import { graphql, Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import SEO from '~/components/Global/SEO';
import Image from '~/components/Image';
import Layout from '~/components/Layout';

const Press: FunctionComponent = ({ data }): ReactElement => {
  const {
    press: { masthead, articles, contact },
    brandAssets,
  } = data.wp.options.globalOptions;

  const pressPosts = data.allWpPressRelease.nodes;

  return (
    <Layout bgcolour="bg-lgrey">
      <SEO title="Press" />

      <main>
        <section className="py-12 md:py-24">
          <div className="container">
            <div className="section-tag">{masthead.tag}</div>
            <h1>{masthead.heading}</h1>
            <div className="max-w-md text-navy">{masthead.content}</div>
            {masthead.emailAddress && (
              <a href={`mailto:${masthead.emailAddress}`} className="text-link">
                {masthead.emailAddress}
              </a>
            )}
          </div>
        </section>
        <section className="py-12 bg-white md:py-24">
          <div className="container">
            <h2 dangerouslySetInnerHTML={{ __html: articles.heading }} />
            <div
              className="max-w-md"
              dangerouslySetInnerHTML={{ __html: articles.subheading }}
            />
            <div className="grid gap-10 mt-12 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              {pressPosts.map(
                ({
                  title,
                  pressPostFields: { publicationName, link, image },
                }) => (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`press-post-${title}`}
                  >
                    <div className="mb-6 rounded-sm img-ratio img-ratio--16:9">
                      <div className="img-ratio__content">
                        <Image
                          data={image}
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                    <div className="section-tag">{publicationName}</div>
                    <h4
                      className="mt-0"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <div className="mt-2 text-link">Keep reading</div>
                  </a>
                )
              )}
            </div>
          </div>
        </section>

        <section className="py-12 bg-lgrey md:py-24">
          <div className="container">
            <div className="flex flex-wrap md:justify-between md:items-end">
              <div className="w-full md:w-auto">
                <h2>{brandAssets.heading}</h2>
                <div className="max-w-md">{brandAssets.subheading}</div>
              </div>
              {brandAssets.link && (
                <Link to={brandAssets.link.url} className="text-link">
                  {brandAssets.link.title}
                </Link>
              )}
            </div>
            <div className="grid gap-10 mt-12 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              {brandAssets.asset.map(({ image, title, content, link }, i) => (
                <Link to={link.url} key={`asset${i}`}>
                  <div className="mb-8 rounded-sm img-ratio img-ratio--16:9">
                    <div className="img-ratio__content">
                      <Image
                        data={image}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </div>
                  <h4 dangerouslySetInnerHTML={{ __html: title }} />
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <div className="text-link">{link.title}</div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </main>

      <section className="relative py-12 bg-bluefade md:py-24">
        <div className="container">
          <div className="flex flex-wrap rounded-sm md:justify-between md:items-center">
            <div>
              <h2
                className="text-white"
                dangerouslySetInnerHTML={{ __html: contact.heading }}
              />
              <p
                className="max-w-md text-white"
                dangerouslySetInnerHTML={{ __html: contact.subheading }}
              />
            </div>
            <div className="flex flex-wrap items-center justify-center px-8 py-8 mt-8 text-center bg-lgrey md:py-10 md:px-12 md:justify-start lg:mt-0 md:text-left">
              <div className="inline-block w-16 h-16 mx-auto mb-4 overflow-hidden rounded-circle md:ml-0 md:mr-6 bg-dgrey md:md-0">
                {contact.image && (
                  <Image
                    data={contact.image}
                    className="object-cover w-full h-full"
                  />
                )}
              </div>
              <div>
                <p>
                  <span className="font-medium text-navy">{contact.name}</span>
                  <br />
                  {contact.title}
                </p>
                {contact.emailAddress && (
                  <a href={contact.emailAddress} className="mt-2 text-link">
                    {contact.emailAddress}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Press;

export const query = graphql`
  query {
    wp {
      options {
        globalOptions {
          press {
            masthead {
              content
              emailAddress
              heading
              tag
            }
            articles {
              heading
              subheading
            }
            contact {
              heading
              subheading
              name
              title
              emailAddress
              image {
                ...Image
              }
            }
          }
          brandAssets {
            heading
            subheading
            link {
              title
              url
            }
            asset {
              image {
                ...Image
              }
              title
              content
              link {
                title
                url
              }
            }
          }
        }
      }
    }
    allWpPressRelease {
      nodes {
        title
        id
        slug
        pressPostFields {
          publicationName
          link
          image {
            ...Image
          }
        }
      }
    }
  }
`;
